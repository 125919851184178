<template>
  <div class="nosotros">
    <BNavbar></BNavbar>

    <div class="py-5 d-flex bg-nosotros align-items-center">
      <b-container fluid>
        <b-row class="py-5">
          <b-col sm="12" md="9" class="mx-auto text-center">
            <div v-if="visible">
              <h1 class="display-4 text-primary font-weight-light">
                <typewriter class="typewriter" :type-interval="30">
                  Usamos la tecnología para encontrar nuevas formas de servir
                </typewriter>
              </h1>
            </div>
          </b-col>
         <b-col sm="12" md="8" class="mx-auto mt-5" style="height: 400px">
              <div class="mx-sm-3" v-if="visibleVideo">
                <iframe width="100%" height="400" src="https://www.youtube.com/embed/videoseries?list=PLdjlpzAdWwf13dA-2mJK93mbSkBSOFSLO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container fluid>
      <b-row class="pb-5 d-flex align-items-center">
        <b-col sm="12" offset-md="1" md="7" lg="5" class="py-5">
          <div class="h1 text-primary font-weight-light mb-5">
            Visión y estrategia
          </div>

          <p class="font-weight-regular lh-28">
            Vivimos en una era de revolución digital. La forma en que
            interactuamos está cambiando a pasos agigantados, nuestros hábitos
            más arraigados se ven desafiados, las estructuras que creíamos más
            seguras ya no lo son. Esto trae grandes oportunidades en cuanto a
            mejorar nuestra calidad de vida y aportar al mundo, y también
            enormes desafíos en cuanto al uso bueno y virtuoso de las nuevas
            herramientas que disponemos.
          </p>

          <p class="font-weight-regular lh-28">
            En Sable reconocemos la tecnología digital como un instrumento al
            servicio de las personas, y también como un gran ecosistema de
            interacciones muy diversas y complejas sobre las que cada parte debe
            cumplir su rol, aprovechando todo lo que aportan las demás, de forma
            colaborativa.
          </p>

          <p class="font-weight-regular lh-28">
            Para ser un ecosistema efectivo dentro de este gran mundo digital,
            debemos ser, a la vez: rentables, innovadores, colaborativos y
            sustentables. Sólo así podremos efectivamente crecer como
            organización, lograr grandes hitos y avanzar día a día un poco más
            en disponer la tecnología al servicio de todos.
          </p>
        </b-col>
        <b-col sm="12" offset-md="0" md="2" offset-lg="2" lg="4" class="p-0">
          <img
            src="/assets/vision-img.webp"
            width="452"
            height="773"
            alt="Vision Grupo Sable"
            class="float-end d-none d-md-block"
          />
          <div class="img-nosotros"></div>
          <b-col md="6" class="border-red"></b-col>
        </b-col>
      </b-row>
    </b-container>

    <div class="">
      <b-container>
        <b-row class="py-5">
          <b-col sm="12">
            <div class="h1 text-primary font-weight-light mb-3">Propósito</div>
          </b-col>
          <b-col sm="12" md="9">
            <div class="font-weight-light h1">
              “Simplificar el día a día y liberar tiempo a las empresas y
              personas a través de ecosistemas digitales.”
            </div>
          </b-col>
          <b-col sm="12" md="7" class="mt-4">
            <p class="font-weight-regular">
              Simplificar es automatizar la solución de problemas complejos, sin
              perder detalle ni trazabilidad. Liberar tiempo es efectivamente
              dar la posibilidad de destinarlo a actividades que generen mayor
              valor y satisfacción.
            </p>
            <p class="font-weight-regular">
              Los ecosistemas digitales son nuestro medio, tenemos uno propio
              que crece día a día y busca conectarse a los demás
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="bg-competencias-valores">
      <b-container class="">
        <b-row class="py-5">
          <b-col sm="12" md="8">
            <div class="h1 text-primary font-weight-light mb-3">
              Competencias que promovemos
            </div>
          </b-col>
          <b-col sm="12" md="7" class="mt-4">
            <div class="d-flex">
              <p class="font-weight-regular card-valores">
                Liderar dualmente, con presente y futuro integrados
              </p>
            </div>
            <div class="d-flex">
              <p class="font-weight-regular card-valores">
                Pensar en forma digital para poder innovar
              </p>
            </div>
            <div class="d-flex">
              <p class="font-weight-regular card-valores">
                Comunicar abundantemente y escuchando
              </p>
            </div>
            <div class="d-flex">
              <p class="font-weight-regular card-valores">
                Empoderar como base para liderar
              </p>
            </div>
          </b-col>

          <b-col sm="12" md="8" class="mt-5">
            <div class="h1 text-primary font-weight-light mb-3">
              Valores que nos sustentan
            </div>
          </b-col>
          <b-col sm="12" md="7" class="mt-4">
            <div class="d-flex">
              <p class="font-weight-regular card-valores">
                Honestidad: Simplemente ser transparente
              </p>
            </div>
            <div class="d-flex">
              <p class="font-weight-regular card-valores">
                Lealtad: Comprender primero, actuar después
              </p>
            </div>
            <div class="d-flex">
              <p class="font-weight-regular card-valores">
                Respeto: El otro siempre es valioso
              </p>
            </div>
            <div class="d-flex">
              <p class="font-weight-regular card-valores">
                Generosidad: Compartir el conocimiento y la motivación
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div class="nosotros-separator py-5">
      <b-container class="py-5">
        <b-col sm="12" class="p-0">
          <div class="h1 text-primary font-weight-light">
            Conversemos <br />el futuro ahora.
          </div>
          <router-link
            v-on:click="scrollToTop"
            to="/contacto"
            class="btn btn-primary text-white mt-4"
            >Contáctanos</router-link
          >
        </b-col>
      </b-container>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import BNavbar from "@/components/BNavbar.vue";
import Footer from "@/components/Footer.vue";
import Typewriter from "typewriter-vue";

export default {
  name: "Home",
  components: {
    BNavbar,
    Footer,
    Typewriter,
  },

  data() {
    return {
      scrollPosition: null,
      visible: false,
      visibleVideo: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    setTimeout(() => (this.visible = true), 1000);
    setTimeout(() => (this.visibleVideo = true), 2600);
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  metaInfo() {
    return {
      title: `Visión y Estrategia | Grupo Sable`,
      meta: [
        {
          name: "description",
          content:
            "En Grupo Sable reconocemos la tecnología digital como un instrumento al servicio de las personas, un gran ecosistema de interacciones colaborativas.",
        },
      ],
      link: [{ rel: "canonical", href: "https://sable.lat/vision" }],
    };
  },
};
</script>
